import { render, staticRenderFns } from "./optionsRequired.vue?vue&type=template&id=c6446564&"
import script from "./optionsRequired.vue?vue&type=script&lang=js&"
export * from "./optionsRequired.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Options: require('/root/containers/hermes_eshop/components/core/options.vue').default})
